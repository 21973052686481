import Rose0 from 'assets/rose-0.png'
import Rose1 from 'assets/rose-1.png'
import Rose2 from 'assets/rose-2.png'
import Rose3 from 'assets/rose-3.png'
import { SecondaryButton } from 'components/Button'
import Centerer from 'components/Centerer'
import { Column, Row } from 'components/Flex'
import { useAudio } from 'hooks/useAudio'
import { useTale } from 'hooks/useTale'
import { useEffect, useMemo } from 'react'
import { Image } from 'rebass'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'

const ROSES = [Rose0, Rose1, Rose2, Rose3]

const CHAPTERS_COUNT = ROSES.length - 1

const MESSAGES = ['Yezgiwv, W Dsms Qsl.', 'Heroiar, I Rodr Yuu.', 'Veysies, Z Pcii Kcl.', "Je t'aime Mon Coeur."]

const Container = styled(Row)`
  width: 100%;
  gap: 64px;
  justify-content: center;
`

const ChaptersContainer = styled(Column)`
  gap: 16px;
  margin-top: 100px;
`

const Rose = styled(Image)`
  width: 250px;
`

const ILU = styled(ThemedText.Body)<{ cypherd: boolean }>`
  position: absolute;
  bottom: 64px;
  margin: 0 auto;

  @keyframes float {
    0% {
      transform: translatey(0px) scale(1.02);
    }
    50% {
      transform: translatey(10px) scale(1);
    }
    100% {
      transform: translatey(0px) scale(1.02);
    }
  }

  animation: float 3s ease-in-out infinite;

  ${({ cypherd }) => cypherd && 'font-family: PixelWingdings;'}
`

const Title = styled(ThemedText.Body)`
  font-size: 64px;
  position: absolute;
  top: 64px;
  text-align: center;
`

export default function FlowerPage() {
  const { heartsCount, openChapter } = useTale()

  const hasCollectedAllHearts = useMemo(() => heartsCount >= CHAPTERS_COUNT, [heartsCount])

  // audio
  const { playLoop } = useAudio()

  useEffect(() => {
    playLoop('panacea')
  }, [playLoop])

  return (
    <>
      <Centerer>
        <Title>
          Héloïse&apos;s Odyssey:
          <br />A Digital Love Letter
        </Title>

        <Column>
          <Container>
            <ChaptersContainer>
              {ROSES.map((_, index) => {
                if (!index) return null

                return (
                  <SecondaryButton
                    key={`chapter-${index}`}
                    onClick={() => openChapter(index)}
                    disabled={index > heartsCount + 1}
                  >
                    Chapter {index}
                  </SecondaryButton>
                )
              })}
            </ChaptersContainer>

            <Rose src={ROSES[heartsCount]} />
          </Container>
        </Column>

        <ILU cypherd={!hasCollectedAllHearts}>{MESSAGES[heartsCount]}</ILU>
      </Centerer>
    </>
  )
}
