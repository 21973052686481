import { Column } from 'components/Flex'
import { styled } from 'styled-components'

const Centerer = styled(Column)`
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
`

export default Centerer
