import { Row } from 'components/Flex'
import { useAudio } from 'hooks/useAudio'
import { useLetter } from 'hooks/useKeyboard'
import { useCallback } from 'react'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'

const Letter = styled(ThemedText.Body)<{ active: boolean }>`
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    50.1% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  ${({ active, theme }) =>
    active &&
    `
      color: ${theme.accent1};
      animation: blink 1s ease-in-out infinite;
    `}
`

interface InputProps {
  length: number
  value: string
  onChange: (newValue: string) => void
}

export default function Input({ length, value, onChange }: InputProps) {
  // audio
  const { playFx } = useAudio()

  const onLetter = useCallback(
    (letter: string) => {
      // cannot add more letters
      if (value.length >= length) return

      playFx('input')
      onChange(value + letter)
    },
    [length, onChange, playFx, value]
  )

  const onBackspace = useCallback(() => {
    // cannot remove more letters
    if (!value.length) return

    playFx('backspace')
    onChange(value.slice(0, -1))
  }, [onChange, playFx, value])

  useLetter(onLetter, onBackspace)

  return (
    <Row gap={12}>
      {Array(length)
        .fill(0)
        .map((_, index) => (
          <Letter key={`input-letter-${index}`} active={index === value.length}>
            {value[index] ?? '_'}
          </Letter>
        ))}
    </Row>
  )
}
