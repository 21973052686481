import { StateCreator } from 'zustand'

import { StoreState } from './index'

export type AudioSlice = State & Actions

// TYPES

export const LOOPS = ['once_upon_a_time', 'start_menu', 'jacob', 'panacea', 'fallen_down_reprise'] as const
export const FXS = ['target', 'txt1', 'heart', 'close', 'bell', 'input', 'backspace', 'success', 'failure'] as const

export type LoopName = (typeof LOOPS)[number]
export type FxName = (typeof FXS)[number]

export type Loops = Record<LoopName, Howl>
export type Fxs = Record<FxName, Howl>

// STATE

interface State {
  loops: Loops | null
  fxs: Fxs | null
  currentLoop: Howl | null
  currentLoopName: LoopName | null
  loadedCount: number
}

interface Actions {
  setCurrentLoop: (loopName: LoopName) => Howl | null
  loadLoops: (loops: Loops) => void
  loadFxs: (fxs: Fxs) => void
  increaseLoadedCount: () => void
}

export const createAudioSlice: StateCreator<StoreState, [['zustand/immer', never]], [], AudioSlice> = (set, get) => ({
  loops: null,
  fxs: null,
  currentLoop: null,
  currentLoopName: null,
  loadedCount: 0,

  setCurrentLoop: (loopName: LoopName) => {
    const loop = get().loops?.[loopName] ?? null

    set({
      currentLoop: loop,
      currentLoopName: loop ? loopName : null, // put null if the loop has not been found
    })

    return loop
  },
  loadLoops: (loops: Loops) => set({ loops }),
  loadFxs: (fxs: Fxs) => set({ fxs }),
  increaseLoadedCount: () => set((state) => ({ loadedCount: state.loadedCount + 1 })),
})
