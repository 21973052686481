import Centerer from 'components/Centerer'
import DialogBox from 'components/DialogBox'
import { Column } from 'components/Flex'
import { useDialog } from 'hooks/useDialog'
import { useEscape } from 'hooks/useKeyboard'
import { useTale } from 'hooks/useTale'
import { useCallback } from 'react'
import { styled } from 'styled-components'
import { ThemedText } from 'theme/components'
import { isMobile } from 'utils/userAgent'

const Section = styled.section`
  max-width: 100vh;
  width: 100vh;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.bg1};
`

const MobileSection = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
`

const Escape = styled(ThemedText.Body)`
  font-size: 16px !important;
  position: absolute;
  top: 16px;
  left: 8px;
`

export default function Layout({ children }: React.PropsWithChildren) {
  const { openChapter, started, currentChapter } = useTale()
  const { resetDialog } = useDialog()

  // back to the flower on escape
  const onEscape = useCallback(() => {
    if (started) {
      openChapter(0)
      resetDialog() // don't forget to close potentially opened dialogs
    }
  }, [openChapter, resetDialog, started])

  useEscape(onEscape)

  if (isMobile) {
    return (
      <MobileSection>
        <Centerer>
          <Column gap={32}>
            <ThemedText.Body textAlign="center" fontSize={24}>
              This experience is not adapted for mobile.
              <br />
              <br />
              Please, use a computer.
            </ThemedText.Body>
          </Column>
        </Centerer>
      </MobileSection>
    )
  }

  return (
    <Section>
      {children}
      <DialogBox />
      {!!currentChapter && <Escape>[ Esc ]</Escape>}
    </Section>
  )
}
