import { useCallback, useEffect } from 'react'

function useKeydown(callback: (event: KeyboardEvent) => void) {
  useEffect(() => {
    document.addEventListener('keydown', callback, false)

    return () => {
      document.removeEventListener('keydown', callback, false)
    }
  }, [callback])
}

function useKey(callback: () => void, key: KeyboardEvent['key']) {
  // verify pressed key and fire callback if needed
  const onKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === key) {
        callback()
      }
    },
    [callback, key]
  )

  // add listeners
  useKeydown(onKeydown)
}

export function useSpaceBar(callback: () => void) {
  useKey(callback, ' ')
}

export function useEscape(callback: () => void) {
  useKey(callback, 'Escape')
}

export function useLetter(onLetter: (key: string) => void, onBackspace: () => void) {
  // verify pressed key and fire callback if needed
  const onKeydown = useCallback(
    (event: KeyboardEvent) => {
      if ('qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM'.includes(event.key)) {
        onLetter(event.key.toLowerCase())
      } else if (event.key === 'Backspace') {
        onBackspace()
      }
    },
    [onBackspace, onLetter]
  )

  // add listeners
  useKeydown(onKeydown)
}
