import { StateCreator } from 'zustand'

import { StoreState } from './index'

export type DialogSlice = State & Actions

export enum Speed {
  INSTANT = 0,
  FAST = 50,
  NORMAL = 100,
  SLOW = 250,
}

export type Dialog = Array<{
  text: string
  speed?: Speed
}>

interface State {
  sprite: string | null
  dialog: Dialog | null
  onDialogComplete: (() => void) | null
  dialogPage: number
}

interface Actions {
  setSprite: (sprite: string | null) => void
  setDialog: (dialog: Dialog | null) => void
  setOnDialogComplete: (onComplete: (() => void) | null) => void
  nextDialogPage: () => void
  resetDialog: () => void
}

const initialState = {
  sprite: null,
  dialog: null,
  onDialogComplete: null,
  dialogPage: 0,
}

export const createDialogSlice: StateCreator<StoreState, [['zustand/immer', never]], [], DialogSlice> = (set) => ({
  ...initialState,

  setSprite: (sprite: string | null) => set({ sprite }),
  setDialog: (dialog: Dialog | null) => set({ dialog, dialogPage: initialState.dialogPage }),
  setOnDialogComplete: (onComplete: (() => void) | null) => set({ onDialogComplete: onComplete }),
  nextDialogPage: () => set((state) => ({ dialogPage: state.dialogPage + 1 })),

  resetDialog: () => set({ ...initialState }),
})
