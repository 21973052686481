import { PrimaryButton } from 'components/Button'
import Centerer from 'components/Centerer'
import { Column } from 'components/Flex'
import { useAudio } from 'hooks/useAudio'
import { useTale } from 'hooks/useTale'
import { useCallback, useEffect } from 'react'
import { ThemedText } from 'theme/components'

export default function IntroPage() {
  // tale
  const { start } = useTale()

  // audio
  const { playLoop, loadAudio, isAudioLoaded, isLoading } = useAudio()

  // start music
  const startMusic = useCallback(() => {
    loadAudio()
  }, [loadAudio])

  // when audio is ready
  useEffect(() => {
    if (isAudioLoaded) {
      playLoop('start_menu')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAudioLoaded])

  if (isAudioLoaded) {
    return (
      <Centerer>
        <Column gap={32}>
          <ThemedText.Body>If you ear music, you can start.</ThemedText.Body>
          <PrimaryButton onClick={start}>START</PrimaryButton>
        </Column>
      </Centerer>
    )
  }

  return (
    <Centerer>
      <Column gap={32}>
        <ThemedText.Body>Better with sound ON.</ThemedText.Body>
        {isLoading ? (
          <ThemedText.Body>Loading...</ThemedText.Body>
        ) : (
          <PrimaryButton onClick={startMusic}>TURN ON MUSIC</PrimaryButton>
        )}
      </Column>
    </Centerer>
  )
}
