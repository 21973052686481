import Chqrles from 'assets/chqrles.png'
import ChqrlesDialog from 'assets/chqrles-dialog.png'
import Heart from 'assets/heart.png'
import Sheet from 'assets/sheet.png'
import Centerer from 'components/Centerer'
import Character from 'components/Character'
import { Column } from 'components/Flex'
import { useAudio } from 'hooks/useAudio'
import { useStartDialog } from 'hooks/useDialog'
import { useTale } from 'hooks/useTale'
import { useCallback, useEffect, useState } from 'react'
import { Dialog, Speed } from 'state/dialog'

const CHQRLES_DIALOG: Dialog = [
  { text: 'My dearest love,' },
  { text: 'First, I apologize for speaking in English. I just feel like I can say whatever I want this way.' },
  { text: "But you see !\nLooks like me, doesn't it !" },

  { text: '^ ^', speed: Speed.SLOW },

  { text: "Anyway, let's get straight to the point..." },
  { text: "There's a specter that haunts our relationship. A ghost from my past, that I cannot banish." },
  { text: 'My ex-girlfriend, a remnant of a time before our paths intertwined,' },
  { text: 'remains an integral part of my life.' },
  { text: "She is more than just a relic of bygone days, she's a confidante, a companion, a friend." },

  { text: '. . .', speed: Speed.SLOW },

  { text: 'But in my attempts to balance the scales of friendship and love,' },
  { text: "I've unwittingly caused you immeasurable pain.", speed: Speed.NORMAL },
  { text: 'My inability to sever ties, to exorcise this lingering spirit,' },
  { text: 'is a failure that weighs heavy on my heart.' },
  { text: 'I am adrift in a sea of indecision, torn between loyalty and love.' },
  { text: 'For how can I abandon a friend in need,' },
  { text: 'even if it means sacrificing the happiness of the one I hold most dear?' },

  { text: '. . .', speed: Speed.SLOW },

  { text: 'Yet in the chaos of my conflicted heart, your presence is a beacon of light.' },
  { text: 'And though I may falter, I feel like I will always find my way back to you.' },
  { text: 'Know that my intentions are pure, though my actions may speak otherwise.' },

  { text: 'With a heavy heart.', speed: Speed.NORMAL },
  { text: 'With all my fractured love.', speed: Speed.NORMAL },
  { text: 'I will remain your Tormented Lover.', speed: Speed.NORMAL },
]

const NOTE_DIALOG: Dialog = [
  { text: '- Seeking for a PIN code?\n- Maybe you could try adding some things together.', speed: Speed.INSTANT },
  { text: '* You turn the note over', speed: Speed.INSTANT },
  { text: '- YOU + ME + US', speed: Speed.INSTANT },
]

const HEART_DIALOG: Dialog = [
  { text: '* You found some LOVE again', speed: Speed.INSTANT },
  { text: '* The flower is almost blossoming again.', speed: Speed.INSTANT },
]

export default function Chapter2Page() {
  const [canTakeHeart, setCanTakeHeart] = useState(false)
  const [canTakeNote, setCanTakeNote] = useState(false)

  // audio
  const { playLoop, playFx } = useAudio()

  useEffect(() => {
    playLoop('jacob')
  }, [playLoop])

  // tale
  const { heartsCount, openChapter, addHeart } = useTale()

  // dialog
  const [isDialoging, startDialog] = useStartDialog()

  // on Chqrles dialog complete
  const onChqrlesDialogComplete = useCallback(() => {
    setCanTakeNote(true)
  }, [])

  // on Heart dialog complete
  const onHeartDialogComplete = useCallback(() => {
    addHeart()
    openChapter(0) // back to the flower
  }, [addHeart, openChapter])

  // on Note dialog complete
  const onNoteDialogComplete = useCallback(() => {
    if (heartsCount >= 2) {
      openChapter(0) // back to the flower
    }

    setCanTakeHeart(true)
  }, [heartsCount, openChapter])

  // talking to chqrles
  const talkToChqrles = useCallback(() => {
    startDialog({ sprite: ChqrlesDialog, dialog: CHQRLES_DIALOG, onComplete: onChqrlesDialogComplete })
  }, [onChqrlesDialogComplete, startDialog])

  // looking at the heart
  const lookAtTheHeart = useCallback(() => {
    startDialog({ dialog: HEART_DIALOG, onComplete: onHeartDialogComplete })
    playFx('heart')
  }, [onHeartDialogComplete, playFx, startDialog])

  // reading the note
  const readTheNode = useCallback(() => {
    startDialog({ dialog: NOTE_DIALOG, onComplete: onNoteDialogComplete })
    playFx('bell')
  }, [onNoteDialogComplete, playFx, startDialog])

  return (
    <Centerer>
      <Column gap={16}>
        {canTakeHeart ? (
          <Character
            src={Heart}
            size={32}
            action={isDialoging ? undefined : { label: 'Look', callback: lookAtTheHeart }}
          />
        ) : canTakeNote ? (
          <Character
            src={Sheet}
            size={32}
            action={isDialoging ? undefined : { label: 'Read', callback: readTheNode }}
          />
        ) : (
          <Character
            src={Chqrles}
            size={64}
            action={isDialoging ? undefined : { label: 'Talk', callback: talkToChqrles }}
          />
        )}
      </Column>
    </Centerer>
  )
}
