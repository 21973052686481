import { useBoundStore } from 'state'

export function useTale() {
  return useBoundStore((state) => ({
    started: state.started,
    start: state.start,

    currentChapter: state.currentChapter,
    openChapter: state.openChapter,

    heartsCount: state.heartsCount,
    addHeart: state.addHeart,
  }))
}
