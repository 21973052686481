import { useCallback } from 'react'
import { useBoundStore } from 'state'
import { Dialog } from 'state/dialog'

export function useDialog() {
  return useBoundStore((state) => ({
    sprite: state.sprite,
    dialog: state.dialog,
    onComplete: state.onDialogComplete,
    page: state.dialogPage,
    nextPage: state.nextDialogPage,
    resetDialog: state.resetDialog,
  }))
}

interface StartDialogParams {
  sprite?: string | null
  dialog: Dialog
  onComplete: () => void
}

export function useStartDialog() {
  const { setSprite, setDialog, setOnDialogComplete, isDialoging } = useBoundStore((state) => ({
    setSprite: state.setSprite,
    setDialog: state.setDialog,
    setOnDialogComplete: state.setOnDialogComplete,
    isDialoging: !!state.dialog,
  }))

  const startDialoging = useCallback(
    ({ sprite = null, dialog, onComplete }: StartDialogParams) => {
      setSprite(sprite)
      setDialog(dialog)
      setOnDialogComplete(onComplete)
    },
    [setDialog, setOnDialogComplete, setSprite]
  )

  return [isDialoging, startDialoging] as const
}
