import Ghost from 'assets/ghost.gif'
import GhostDialog from 'assets/ghost-dialog.png'
import Heart from 'assets/heart.png'
import Centerer from 'components/Centerer'
import Character from 'components/Character'
import { Column } from 'components/Flex'
import { useAudio } from 'hooks/useAudio'
import { useStartDialog } from 'hooks/useDialog'
import { useTale } from 'hooks/useTale'
import { useCallback, useEffect, useState } from 'react'
import { Dialog, Speed } from 'state/dialog'

const GHOST_DIALOG: Dialog = [
  { text: "Hey !\nNice to meet you, I'm Enir Maght." },
  { text: 'Make sure to remember my name.', speed: Speed.NORMAL },
  { text: "I'm one of the first pixel art creation of Chqrles'.\nThat's why I look so cool hehe." },
  { text: 'I can say that I know him for a long time.' },
  { text: "You may be wondering why you've stumbled upon this curious digital realm." },
  { text: 'Guided by a spectral presence\n. . .' },
  { text: "It's an unconventional gesture, I admit. But you don't have a conventional partner." },
  { text: '^ ^', speed: Speed.SLOW },
  { text: 'You see, in the labyrinth of his mind,\nemotions often take cryptic forms.' },
  { text: 'And your loved one is not adept at the language of flowers or grand romantic gestures.' },
  { text: 'His love is woven into the very fabric of this virtual sanctuary.' },
  { text: '. . .', speed: Speed.SLOW },
  { text: "He's not gifted with eloquence, nor does he excel in the dance of social interactions" },
  { text: 'But here, in this digital cathedral, he can bare his soul in a way that feels genuine.' },
  { text: 'So, as you navigate these electronic corridors, know that every pixel, every line of code,' },
  { text: 'is a testament to the depths of his affection.' },
  { text: 'For in this realm, he is most truly himself.', speed: Speed.NORMAL },
  { text: 'With all his love.', speed: Speed.NORMAL },
  { text: 'Have a great time.' },
]

const HEART_DIALOG: Dialog = [
  { text: '* You found some LOVE', speed: Speed.INSTANT },
  { text: '* The flower is feeling better.', speed: Speed.INSTANT },
]

export default function Chapter1Page() {
  const [canTakeHeart, setCanTakeHeart] = useState(false)

  // audio
  const { playLoop, playFx } = useAudio()

  useEffect(() => {
    playLoop('once_upon_a_time')
  }, [playLoop])

  // tale
  const { heartsCount, openChapter, addHeart } = useTale()

  // dialog
  const [isDialoging, startDialog] = useStartDialog()

  // on Ghost dialog complete
  const onGhostDialogComplete = useCallback(() => {
    if (heartsCount >= 1) {
      openChapter(0) // back to the flower
    }

    setCanTakeHeart(true)
  }, [openChapter, heartsCount])

  // on Heart dialog complete
  const onHeartDialogComplete = useCallback(() => {
    addHeart()
    openChapter(0) // back to the flower
  }, [addHeart, openChapter])

  // talking to ghost
  const talkToGhost = useCallback(() => {
    startDialog({ sprite: GhostDialog, dialog: GHOST_DIALOG, onComplete: onGhostDialogComplete })
  }, [onGhostDialogComplete, startDialog])

  // looking at the heart
  const lookAtTheHeart = useCallback(() => {
    startDialog({ dialog: HEART_DIALOG, onComplete: onHeartDialogComplete })
    playFx('heart')
  }, [onHeartDialogComplete, playFx, startDialog])

  return (
    <Centerer>
      <Column gap={16}>
        {canTakeHeart ? (
          <Character
            src={Heart}
            size={32}
            action={isDialoging ? undefined : { label: 'Look', callback: lookAtTheHeart }}
          />
        ) : (
          <Character src={Ghost} action={isDialoging ? undefined : { label: 'Talk', callback: talkToGhost }} />
        )}
      </Column>
    </Centerer>
  )
}
