import Chqrles from 'assets/chqrles.png'
import ChqrlesDialog from 'assets/chqrles-dialog.png'
import Heart from 'assets/heart.png'
import KoreaNote from 'assets/korea-note.jpg'
import SeoulBackground from 'assets/seoul.jpg'
import Centerer from 'components/Centerer'
import Character from 'components/Character'
import { Column } from 'components/Flex'
import Input from 'components/Input'
import { useAudio } from 'hooks/useAudio'
import { useStartDialog } from 'hooks/useDialog'
import { useTale } from 'hooks/useTale'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Image } from 'rebass'
import { Dialog, Speed } from 'state/dialog'
import { styled } from 'styled-components'
import { ThemedText } from 'theme/components'

const PASSWORD = 'korea'

const CHQRLES_DIALOG: Dialog = [
  { text: "Hey !\nI'm glad you came all the way here !" },
  { text: "I hope you're enjoying this journey as much as I've enjoyed creating it for you" },
  { text: 'It was a way for me to give you what I treasure the most.' },

  { text: 'My time.', speed: Speed.SLOW },

  { text: "I've also made few videos through the process, you'll find them in `recent-memory.zip`" },
  { text: "And as you've always wanted to see me crying...\n" },
  { text: 'I even took a photo while I was writting all these dialogs ^ ^' },
  { text: "Anyway, I'll give you the password at the end." },

  { text: 'So.', speed: Speed.NORMAL },

  { text: "This is already the last chapter and I bet you didn't learn a lot of new things." },
  { text: "But I've been pondering our situation, and I've come up with an idea I'd like to share with you." },

  { text: 'Pyongyang has always been your dream destination' },
  { text: "Or maybe it's Seoul..." },
  { text: 'But what is certain is that a change of scenery could provide the fresh start we both need.' },

  { text: "So, here's the proposal", speed: Speed.NORMAL },

  {
    text: 'We leave Paris behind for a year at least and embark on a journey of exploration and rediscovery in Korea',
  },
  { text: 'And I would be fully committed to financially supporting us during this time.' },
  { text: 'You may have noticed I left some money in the USB key box ^ ^' },
  { text: 'Double that amount and I will give you that every month, the rest is for both of us.' },
  { text: "I've worked hard and sacrificed a lot to achieve this level of wealth, but you've always supported me" },
  { text: 'So you deserve it.' },
  { text: 'You would have the freedom to immerse yourself in the culture, pursue your passions,' },

  { text: 'and find your Ikigai.', speed: Speed.SLOW },

  { text: "This isn't about running away, it's about embracing a new chapter together." },
  { text: "Take your time to consider it, and let's discuss how we can turn this dream into a reality." },
  { text: "I also need to think about it some more, but I think I'm ready." },

  { text: 'I almost forgot !' },
  { text: 'The password is easy.', speed: Speed.NORMAL },
  { text: 'Just type the name of your lover...', speed: Speed.SLOW },
]

const HEART_DIALOG: Dialog = [
  { text: '* You found the very last piece of LOVE', speed: Speed.INSTANT },
  { text: '* The flower is finally a flourishing beauty.', speed: Speed.INSTANT },
  { text: '* It will never wither.', speed: Speed.INSTANT },
]

const KOREA_NOTE_DIALOG: Dialog = [
  { text: "* It's a picture of South Korea", speed: Speed.INSTANT },
  { text: '* You turn the picture over', speed: Speed.INSTANT },
  { text: "* There's a drawing of a couple in love", speed: Speed.INSTANT },
  { text: '* They seem to be in Seoul', speed: Speed.INSTANT },
  { text: "- Radiant souls amidst Seoul's embrace.", speed: Speed.INSTANT },
]

const Error = styled(ThemedText.Body)<{ display: boolean }>`
  color: ${({ theme }) => theme.error};

  @keyframes shake {
    33% {
      transform: translateX(-12px);
    }

    66% {
      transform: translateX(12px);
    }
  }

  visibility: ${({ display }) => (display ? 'visible' : 'hidden')};
  animation: ${({ display }) => (display ? 'shake 250ms ease-in-out' : 'none')};
`

const Background = styled(Image)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  opacity: 0.5;
`

export default function Chapter3Page() {
  const [canTakeHeart, setCanTakeHeart] = useState(false)
  const [canTakeKoreaNote, setCanTakeKoreaNote] = useState(true)
  const [password, setPassword] = useState('')

  // audio
  const { playLoop, playFx } = useAudio()

  // password
  const isUnlocked = useMemo(() => password === PASSWORD, [password])

  const badPassword = useMemo(() => password.length === PASSWORD.length && !isUnlocked, [isUnlocked, password.length])

  // play password fxs
  useEffect(() => {
    if (isUnlocked) {
      playFx('success')
      playLoop('fallen_down_reprise')
    } else {
      playLoop('start_menu')
    }
  }, [isUnlocked, playFx, playLoop])

  useEffect(() => {
    if (badPassword) {
      playFx('failure')
    }
  }, [badPassword, playFx])

  // tale
  const { heartsCount, openChapter, addHeart } = useTale()

  // dialog
  const [isDialoging, startDialog] = useStartDialog()

  // on Chqrles dialog complete
  const onChqrlesDialogComplete = useCallback(() => {
    if (heartsCount >= 3) {
      openChapter(0) // back to the flower
    }

    setCanTakeHeart(true)
  }, [heartsCount, openChapter])

  // on Heart dialog complete
  const onHeartDialogComplete = useCallback(() => {
    addHeart()
    openChapter(0) // back to the flower
  }, [addHeart, openChapter])

  // on Heart dialog complete
  const onKoreaNoteDialogComplete = useCallback(() => {
    setCanTakeKoreaNote(false)
  }, [])

  // talking to chqrles
  const talkToChqrles = useCallback(() => {
    startDialog({ sprite: ChqrlesDialog, dialog: CHQRLES_DIALOG, onComplete: onChqrlesDialogComplete })
  }, [onChqrlesDialogComplete, startDialog])

  // looking at the heart
  const lookAtTheHeart = useCallback(() => {
    startDialog({ dialog: HEART_DIALOG, onComplete: onHeartDialogComplete })
    playFx('heart')
  }, [onHeartDialogComplete, playFx, startDialog])

  // looking at the korea note
  const lookAtTheKoreaNote = useCallback(() => {
    startDialog({ dialog: KOREA_NOTE_DIALOG, onComplete: onKoreaNoteDialogComplete })
    playFx('bell')
  }, [onKoreaNoteDialogComplete, playFx, startDialog])

  if (isUnlocked) {
    return (
      <>
        <Centerer>
          <Column gap={16}>
            {canTakeKoreaNote ? (
              <Character
                src={KoreaNote}
                size={128}
                action={isDialoging ? undefined : { label: 'Look', callback: lookAtTheKoreaNote }}
              />
            ) : canTakeHeart ? (
              <Character
                src={Heart}
                size={32}
                action={isDialoging ? undefined : { label: 'Look', callback: lookAtTheHeart }}
              />
            ) : (
              <Character
                src={Chqrles}
                size={64}
                action={isDialoging ? undefined : { label: 'Talk', callback: talkToChqrles }}
              />
            )}
          </Column>
        </Centerer>

        {!canTakeKoreaNote && !canTakeHeart && <Background src={SeoulBackground} />}
      </>
    )
  } else {
    return (
      <Centerer>
        <Column gap={32}>
          <ThemedText.Body>Password required</ThemedText.Body>
          <Input length={PASSWORD.length} value={password} onChange={setPassword} />
          <Error display={badPassword}>Wrong.</Error>
        </Column>
      </Centerer>
    )
  }
}
