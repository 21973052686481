import { PrimaryButton } from 'components/Button'
import { Image } from 'rebass'
import { styled } from 'styled-components'

const StyledCharacter = styled.div`
  position: relative;
`

const Sprite = styled(Image)<{ $size: number }>`
  width: ${({ $size }) => $size}px;
`

const ButtonContainer = styled.div`
  position: absolute;
  bottom: -64px;
  width: fit-content;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
`

interface CharacterProps {
  src: string
  size?: number
  action?: {
    label: string
    callback: () => void
  }
}

export default function Character({ src, size = 100, action }: CharacterProps) {
  return (
    <StyledCharacter>
      <Sprite src={src} $size={size} />

      {action && (
        <ButtonContainer>
          <PrimaryButton onClick={action.callback}>{action.label}</PrimaryButton>
        </ButtonContainer>
      )}
    </StyledCharacter>
  )
}
