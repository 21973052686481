import { Howl } from 'howler'
import { useCallback, useMemo, useState } from 'react'
import { useBoundStore } from 'state'
import { FxName, FXS, Fxs, LoopName, LOOPS, Loops } from 'state/audio'

const FX_VOLUMES: Partial<Record<FxName, number>> = {
  close: 0.3,
  success: 0.2,
  failure: 0.2,
  input: 0.5,
  backspace: 0.5,
}

export function useAudio() {
  const [hasStartedToLoad, setHasStartedToLoad] = useState(false)

  const {
    currentLoop,
    currentLoopName,
    setCurrentLoop,
    loops,
    loadLoops,
    fxs,
    loadFxs,
    loadedCount,
    increaseLoadedCount,
  } = useBoundStore((state) => ({
    currentLoop: state.currentLoop,
    currentLoopName: state.currentLoopName,
    setCurrentLoop: state.setCurrentLoop,

    loops: state.loops,
    loadLoops: state.loadLoops,

    fxs: state.fxs,
    loadFxs: state.loadFxs,

    loadedCount: state.loadedCount,
    increaseLoadedCount: state.increaseLoadedCount,
  }))

  const loadAudio = useCallback(() => {
    setHasStartedToLoad(true)

    if (!fxs) {
      loadFxs(
        FXS.reduce<Fxs>((acc, fxName) => {
          acc[fxName] = new Howl({
            src: [`${process.env.PUBLIC_URL}/audio/fxs/${fxName}.wav`],
            volume: FX_VOLUMES[fxName] ?? 1,
            onload: increaseLoadedCount,
          })
          return acc
        }, {} as Fxs)
      )
    }

    if (!loops) {
      loadLoops(
        LOOPS.reduce<Loops>((acc, loopName) => {
          acc[loopName] = new Howl({
            src: [`${process.env.PUBLIC_URL}/audio/loops/${loopName}.mp3`],
            loop: true,
            volume: 0.4,
            onload: increaseLoadedCount,
          })

          return acc
        }, {} as Loops)
      )
    }
  }, [fxs, increaseLoadedCount, loadFxs, loadLoops, loops])

  // Function to change the current loop
  const playLoop = useCallback(
    (loopName: LoopName) => {
      if (loopName === currentLoopName) return

      currentLoop?.stop()
      const newLoop = setCurrentLoop(loopName)
      newLoop?.play()
    },
    [currentLoop, currentLoopName, setCurrentLoop]
  )

  // Function to play sound effect
  const playFx = useCallback(
    (fxName: FxName) => {
      fxs?.[fxName].play()
    },
    [fxs]
  )

  // is loaded
  const isAudioLoaded = useMemo(() => loadedCount === LOOPS.length + FXS.length, [loadedCount])

  // is loading
  const isLoading = useMemo(() => hasStartedToLoad && !isAudioLoaded, [hasStartedToLoad, isAudioLoaded])

  return { playLoop, playFx, loadAudio, isAudioLoaded, isLoading }
}
