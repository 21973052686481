import { Row } from 'components/Flex'
import { useAudio } from 'hooks/useAudio'
import { useDialog } from 'hooks/useDialog'
import { useSpaceBar } from 'hooks/useKeyboard'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Image } from 'rebass'
import { Dialog, Speed } from 'state/dialog'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'

const StyledDialogBox = styled(Row)`
  position: absolute;
  bottom: 32px;
  left: 32px;
  right: 32px;
  height: 200px;
  border: 5px solid ${({ theme }) => theme.neutral1};
  padding: 64px 32px;
  gap: 42px;
  align-items: center;
  background: ${({ theme }) => theme.bg1};
`

const Sprite = styled(Image)`
  width: 100px;
  height: 100px;
  flex-shrink: 0;
`

const TextContainer = styled(Row)`
  height: 126px;
  align-items: flex-start;
  padding: 1px;

  & > * {
    padding: 1px;
  }
`

const Space = styled(ThemedText.Body)`
  font-size: 16px !important;
  position: absolute;
  bottom: 4px;
  right: 8px;
`

export default function DialogBox() {
  const [invisibleText, setInvisibleText] = useState('')
  const [isWritting, setIsWritting] = useState(false)

  // dialog state
  const { sprite, dialog, onComplete, page, nextPage, resetDialog } = useDialog()

  // current text
  const { text = '', speed = Speed.FAST } = useMemo(() => dialog?.[page] ?? ({} as Dialog[number]), [dialog, page])

  // audio
  const { playFx } = useAudio()

  // next page on space bar press if not writting
  const nextPageIfNeeded = useCallback(() => {
    if (isWritting) {
      // you can only skip animation during fast dialogs
      if (speed == Speed.FAST) {
        setInvisibleText('')
      }
    } else {
      nextPage()
    }
  }, [isWritting, nextPage, speed])

  useSpaceBar(nextPageIfNeeded)

  // reset dialog box on new text
  useEffect(() => {
    if (!text.length) return

    setInvisibleText(text)
    setIsWritting(true)
  }, [text])

  // type effect loop
  useEffect(() => {
    if (!text.length || !isWritting) return

    // no need to go through the typing loop
    if (speed == Speed.INSTANT) {
      setInvisibleText('')
      return
    }

    // loop
    const intervalId = setInterval(() => {
      setInvisibleText((state) => state.trim().slice(1))

      // skip some FXs
      if (Math.random() > 0.2 || speed != Speed.FAST) {
        playFx('txt1')
      }
    }, speed)

    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, isWritting, speed])

  // stop writting
  useEffect(() => {
    setIsWritting(!!invisibleText.length)
  }, [invisibleText])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const displayedText = useMemo(() => text.slice(0, text.length - invisibleText.length), [invisibleText])

  // can press space
  const canPressSpace = useMemo(() => !isWritting || speed == Speed.FAST, [isWritting, speed])

  // onComplete
  useEffect(() => {
    if (!text.length && onComplete) {
      onComplete()
      resetDialog()
      setInvisibleText('')
      playFx('close')
    }
  }, [onComplete, text, resetDialog, playFx])

  if (!dialog) {
    return null
  }

  return (
    <StyledDialogBox>
      {!!sprite && <Sprite src={sprite} />}

      <TextContainer>
        <ThemedText.Body fontSize={28}>
          {displayedText}
          <span style={{ color: '#000' }}>{invisibleText}</span>
        </ThemedText.Body>
      </TextContainer>

      {canPressSpace && <Space>[ SPACE ]</Space>}
    </StyledDialogBox>
  )
}
