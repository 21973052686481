import { StateCreator } from 'zustand'

import { StoreState } from './index'

export type ApplicationSlice = State & Actions

interface State {
  started: boolean
  currentChapter: number
  heartsCount: number
}

interface Actions {
  start: () => void
  openChapter: (chapter: number) => void
  addHeart: () => void
}

export const createApplicationSlice: StateCreator<StoreState, [['zustand/immer', never]], [], ApplicationSlice> = (
  set
) => ({
  started: false,
  currentChapter: 0,
  heartsCount: 0,

  start: () => set({ started: true }),
  openChapter: (chapter: number) => set({ currentChapter: chapter }),
  addHeart: () => set((state) => ({ heartsCount: state.heartsCount + 1 })),
})
