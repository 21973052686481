import { useTale } from 'hooks/useTale'

import Chapter1Page from './Chapter1'
import Chapter2Page from './Chapter2'
import Chapter3Page from './Chapter3'
import FlowerPage from './Flower'
import IntroPage from './Intro'

export default function HomePage() {
  const { started, currentChapter } = useTale()

  if (!started) {
    return <IntroPage />
  }

  if (currentChapter == 1) {
    return <Chapter1Page />
  }

  if (currentChapter == 2) {
    return <Chapter2Page />
  }

  if (currentChapter == 3) {
    return <Chapter3Page />
  }

  return <FlowerPage />
}
